/**
 * Site header
 */

.site-header {
    border-bottom: 1px solid $grey-color-light;
    background-color: $header-background-color;
    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 56px;
    font-weight: 400;
    line-height: 46px;
    margin-right: 16px;
    margin-top: 16px;
    float: right;
    text-shadow: 0px 0px 8px #000000;
    text-decoration: none;
    text-align: right;
}

a.site-title {
    color: $title-text-color;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.site-masthead {
    background-image:url( $baseurl + '/media/header.jpg');
    width: 100%;
    background-size: 1000px;
    min-height: 200px;
}

div.menu {
    margin-top: 1px;
    margin-bottom: 1px;
}

nav {
    ul {
        float:left;
        margin: 0;
        padding: 0;
        list-style: none;
        min-height: 24px;
    }

    li {
        float: left;
        margin: 1px;
            @include media-query($on-mobile) {
            display: inline-block;
            width: 100%;
            }
    }

    a {
        display: block;
        padding: 4px 16px 4px;
        text-decoration: none;
        font-size: $menu-font-size;
        color: $menu-text-color;
            &:visited {
                color: $menu-text-color;
            }
            &:hover {
                color: $menu-text-color;
                text-decoration: none;
            }
    }
}

.page_item {
    background-color: #222;
        &:hover {
            background-color: #333;
        }
        &:active {
            background-color: #444;
        }
}

.selected_page_item {
    background-color: #333;
        &:active {
            background-color: #444;
        }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.footer-credit {
    text-align: center;
}

.pagination {
float: left;
}

.rss-subscribe {
float: right;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.about-photo {
        width: 100%;
        height: auto;
        border-radius: 4px;
        margin-bottom: 20px;
}

.about-col-wrapper {
    font-size: 15px;
    margin-left: calc(-1 * $spacing-unit / 2);
    @extend %clearfix;
}

.about-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.about-col-1 {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
    padding-right: 5%;
}

.about-col-2 {
    width: -webkit-calc(55% - (#{$spacing-unit} / 2));
    width:         calc(55% - (#{$spacing-unit} / 2));
}

@include media-query($on-mobile) {
    .about-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

/**
 * Pages & Posts
 */
.page-content {
    padding: $spacing-unit 0;
    @extend %clearfix;
}

.page-section {
    padding-top: 16px;
    padding-bottom: 16px;
    @extend %clearfix;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

.post-header {
    margin-top: 30px;
    a {
        color: $text-color;
    }
}

.post-title {
    font-size: 42px;
    line-height: 1.25;
}

.post-content {
    margin-bottom: 60px;
    h1 {
        font-size: 42px;
    }
    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 20px;
    }
    @extend %clearfix;
}

.photo-left-aligned {
    float: left;
    vertical-align:top;
    margin-left:0px;
    margin-top:0px;
    margin-right:16px;
    margin-bottom:8px;
}

.photo-right-aligned {
    float: right;
    vertical-align:top;
    margin-left:16px;
    margin-top:0px;
    margin-right:0px;
    margin-bottom:8px;
}

.photo-center-aligned {
    text-align: center;
    vertical-align:top;
    margin-left:0px;
    margin-top:0px;
    margin-right:0px;
    margin-bottom:8px;
}

.data-table {
    font-size: $small-font-size;
    td, th {
        padding-left: 4px;
        padding-right: 4px;
    }
    border-collapse: collapse;
    border: 1px solid #ddd;
    tr:nth-child(even) {background-color: #f2f2f2}
}

.image-thumb {
    max-width: $thumbnail-size;
    max-height: $thumbnail-size;
}

.image-big-thumb {
    max-width: Min($big-thumbnail-size, 100%);
    max-height: $big-thumbnail-size;
}

.video-big {
    width: Min($big-video-size, 100%);
    height: auto;
}

.image-container {
    display:inline-block;
}

.image-padding-container {
    display:inline-block;
    margin-left:4px;
    margin-top:0px;
    margin-right:4px;
    margin-bottom:8px;
}
