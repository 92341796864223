@charset "utf-8";


// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$thumbnail-size:    (calc(320/2) * 1px);
$big-thumbnail-size:    (calc(960/2) * 1px);
$big-video-size:    (calc(1152/2) * 1px);

$text-color:       #555;
$background-color: #fdfdfd;
$link-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Header
$title-text-color: #fff;
$menu-text-color: #f4d49d;
$header-background-color: #110E07;
$menu-font-size:  $base-font-size * 1.25;


// Width of the content area
$content-width:    800px;

$on-mobile:          425px;

$baseurl: '';

// Use media queries like this:
// @include media-query($on-mobile) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
